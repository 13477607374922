.react-time-picker {
  margin: auto;
  margin-bottom: 8px;
}
.react-time-picker__wrapper {
  padding-left: 12px;
  padding-right: 12px;
  height: 2.5rem;
  border-radius: 0.375rem;
  border-color: inherit;
  border: 1px solid #d9d9d9;
}
.react-time-picker__inputGroup {
  min-width: 0;
}
.react-timerange-picker__inputGroup__leadingZero {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
}
